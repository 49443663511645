/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {observer} from 'mobx-react';
import ProfileField from '../form/ProfileField';

function ProfileEssentials({id, section, questions, canEdit, firstname, printing}) {
  // eslint-disable-next-line fp/no-mutating-methods
  const order = useMemo(() => Object.keys(questions.fields).sort((a, b) => questions.fields[a].index - questions.fields[b].index), [questions]);

  return (
    <div className="profile-form-container">
      {order.map(key => {
        const label = (questions.fields[key].label || '').replace('FIRST_NAME', firstname);
        const placeholder = (questions.fields[key].placeholder || '').replace('FIRST_NAME', firstname);
        if (printing) {
          switch (key) {
            case 'image':
              return null;
            case 'date':
              const value = new Date(section[key].value);
              const formattedValue = `${value.getDate().toString().padStart(2, '0')}/${(value.getMonth() + 1)
                .toString()
                .padStart(2, '0')}/${value.getFullYear()}`;
              return (
                <label className="profile-form__label">
                  {label} <p>{formattedValue}</p>
                </label>
              );
            default:
              return (
                <label className="profile-form__label">
                  {label} <p>{section[key].value || ''}</p>
                </label>
              );
          }
        }
        return (
          <ProfileField
            {...questions.fields[key]}
            profileId={id}
            answer={section[key]}
            label={label}
            placeholder={placeholder}
            key={key}
            field={key}
            canEdit={canEdit}
          />
        );
      })}
    </div>
  );
}

export default observer(ProfileEssentials);
