/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState} from 'react';
import {observer, inject} from 'mobx-react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import {Card, Icon} from '../common';
import User from '../user';
import ProfileAside from './profile.aside';
import getIsOwner from '../../helpers/getIsOwner';
import {getFullName} from '../../helpers/getFullName';

import ModalInviteTeamMember from '../invite/modal.invite';

const isProfessional = permission => (typeof permission === 'object' ? permission.type === 'professional' : false);

function ProfileTeam({invite, profile: selectedProfile, stores: {profilesStore, uiStore}, match}) {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(invite);
  const [isOwner, setIsOwner] = useState(false);
  const [professionalMembers, setProfessionalMembers] = useState([]);
  const [profile, setProfile] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const newProfile = profilesStore.getProfileById(uiStore.currentProfileId);

    if (!isEqual(newProfile, profile)) {
      setProfile(newProfile);
      setIsOwner(getIsOwner(newProfile));

      // eslint-disable-next-line fp/no-mutating-methods
      const newTeamMembers = Object.values(newProfile.team || {})
        .filter(user => !isProfessional(newProfile.permissions[user.id]))
        .sort((a, b) => {
          const aFullname = getFullName(a).toUpperCase();
          const bFullname = getFullName(b).toUpperCase();
          return aFullname.localeCompare(bFullname);
        });

      // eslint-disable-next-line fp/no-mutating-methods
      const newProfessionalMembers = Object.values(newProfile.team || {})
        .filter(user => isProfessional(newProfile.permissions[user.id]))
        .sort((a, b) => {
          const aFullname = getFullName(a).toUpperCase();
          const bFullname = getFullName(b).toUpperCase();
          return aFullname.localeCompare(bFullname);
        });

      setTeamMembers(newTeamMembers);
      setProfessionalMembers(newProfessionalMembers);
    }
  }, [profile, profilesStore, uiStore]);

  const openTeamInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  const closeTeamInviteModal = () => {
    setIsInviteModalOpen(false);
  };

  if (isEmpty(profile)) return null;

  return (
    <div className="container">
      <aside className="aside">
        <ProfileAside profileId={profile.id} />
      </aside>
      <Card feed>
        <div className="section-team -with-page-action">
          <div className="team-heading">
            <h3>Family Team</h3>
          </div>
          <div className="team-users">
            {teamMembers.map(teamMember => (
              <User user={teamMember} profile={profile} key={teamMember.id} open={teamMember.id === match.params.userId} />
            ))}
          </div>
          {professionalMembers && (
            <>
              <div className="team-heading">
                <h3>Professional Team</h3>
              </div>
              <div className="team-users">
                {professionalMembers.map(teamMember => (
                  <User user={teamMember} profile={profile} key={teamMember.id} isProfessional open={teamMember.id === match.params.userId} />
                ))}
              </div>
            </>
          )}
          <ModalInviteTeamMember profile={profile} isOpen={isInviteModalOpen} onDismiss={closeTeamInviteModal} />
          {isOwner && (
            <button type="button" className="page-action" onClick={openTeamInviteModal}>
              <span className="button -toggle -small -inverse">
                <Icon type="plus" />
              </span>
              Add Team Member
            </button>
          )}
        </div>
      </Card>
    </div>
  );
}

export default inject('stores')(observer(ProfileTeam));
