/* eslint-disable fp/no-mutation */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState, useCallback} from 'react';
import {FileIcon, defaultStyles} from 'react-file-icon';
import {inject, observer} from 'mobx-react';
import {MentionsInput, Mention} from 'react-mentions';
import {withRouter} from 'react-router-dom';
import posed, {PoseGroup} from 'react-pose';
import {Avatar, Icon, Progress, Button} from '../common';
import {db} from '../../firebase';
import {errorLogger} from '../../helpers/errorLogger';
import FileUpload from '../file-upload/file-upload';
import getIsViewer from '../../helpers/getIsViewer';
import GoalSelection from '../goal/goal.post.selection';
import routes from '../../constants/routes';
import withAuthorization from '../session/withAuthorization';

const PosedSection = posed.section({
  enter: {height: 'auto', flip: true},
  exit: {height: 0},
});

// eslint-disable-next-line no-bitwise
const getExt = fname => fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2);

const placeholderPngSrc =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAAAyCAYAAAAUYybjAAAAYElEQVR42u3QQREAAAQAMOI6f1/VVRBgi7Ccrg1eUpYsWbJkyZIlS5YsWbJkyZIlS5YsWbJkyZIlS5YsWbJkyZIlS5YsWbJkyZIlS5YsWbJkyZIlS5YsWbJkyZIlS9bfAfvBWXWGB00fAAAAAElFTkSuQmCC';

function PostCreate(props) {
  const {
    stores: {profilesStore},
    onboard,
    match,
    onCancel,
    history,
    authUser,
  } = props;

  const [activeProfile, setActiveProfile] = useState(null);
  const [selectedSteps, setSelectedSteps] = useState({});
  // const [selectedTeamMembers, setSelectedTeamMembers] = useState({});
  const [text, setText] = useState('');
  const [error, setError] = useState('');
  const [uploads, setUploads] = useState({});
  const [extended, setExtended] = useState(false);
  // const [openTags, setOpenTags] = useState(false);
  const [tags, setTags] = useState([]);
  const [isViewingProfile, setIsViewingProfile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isViewer, setIsViewer] = useState(false);

  useEffect(() => {
    // if (nextProps.match.params.profileId !== this.props.match.params.profileId) {
    //   this.setState({
    //     activeProfile: getActiveProfileFromParams(nextProps),
    //   });
    // }

    setActiveProfile(getActiveProfileFromParams(props));
  }, [props]);

  useEffect(() => {
    if (activeProfile) {
      setIsViewer(getIsViewer(activeProfile));

      const newTags = Object.keys(activeProfile?.team ?? {}).map(id => ({
        id,
        display: `${activeProfile.team[id].firstname} ${activeProfile.team[id].lastname}`,
        profileImagePublic: activeProfile.team[id].profileImagePublic,
      }));

      setTags(newTags);
    }
  }, [activeProfile]);

  useEffect(() => {
    setIsViewingProfile(!!match.params.profileId);
  }, [match]);

  useEffect(() => {
    const isFileUploading = () => !!Object.values(uploads).find(({done}) => !done);
    setIsUploading(isFileUploading);
  }, [uploads]);

  const handleUpdateSelectedProfile = e => {
    const profileId = e.target.value;
    const newActiveProfile = profilesStore.profiles.find(p => p.id === profileId);

    setActiveProfile(newActiveProfile);
    setSelectedSteps({});
    // setSelectedTeamMembers({});
  };

  const handleClickStep = (goalId, name) => {
    // Want to toggle the active steps here
    setSelectedSteps({
      ...selectedSteps,
      [goalId]: selectedSteps[goalId] ? false : name,
    });
  };

  const onFileUpload = useCallback((file, url, storagePath) => {
    if (file.type.match(/(image|video).*/)) {
      URL.revokeObjectURL(file);
    }

    setUploads(prevState => ({
      ...prevState,
      [file.uniqFileName]: {
        ...prevState[file.uniqFileName],
        storagePath,
        uniqFileName: file.uniqFileName,
        postFilePublic: url,
        postFile: file.name,
        ext: getExt(file.name),
        type: file.type,
        done: true,
      },
    }));
  }, []);

  const onFileProgress = useCallback((file, progress) => {
    let fileUpload = {...file};

    if (!('postFilePreview' in fileUpload)) {
      fileUpload.postFilePreview = placeholderPngSrc;

      switch (file.type.split('/')[0]) {
        case 'image':
          fileUpload.postFilePreview = URL.createObjectURL(file);

          // eslint-disable-next-line no-case-declarations
          const image = new Image();
          image.onload = () => {
            const canvas = document.createElement('canvas');
            const maxSize = 10;
            let {width} = image;
            let {height} = image;

            if (width > height) {
              if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
              }
            } else if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }

            canvas.width = width;
            canvas.height = height;
            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
            const postFilePublicLqip = canvas.toDataURL('image/jpeg');

            fileUpload = {
              ...fileUpload,
              postFile: fileUpload.name,
              ext: getExt(fileUpload.name),
              type: fileUpload.type,
              postFilePublicLqip,
            };
          };
          image.src = fileUpload.postFilePreview;

          break;

        case 'video':
          {
            // https://stackoverflow.com/questions/11261448/php-extract-frame-during-video-upload
            const url = URL.createObjectURL(file);
            const video = document.createElement('video');

            const snapshot = async () => {
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');

              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              const cw = canvas.width;
              const ch = canvas.height;

              const c = {
                // the circle: coords of the center and the radius
                x: cw / 2,
                y: ch / 2,
                r: 18,
              };

              const points = []; // the vertices array
              const angle = (2 * Math.PI) / 3;

              for (let i = 0; i < 3; i += 1) {
                const o = {};
                o.x = c.x + c.r * 0.6 * Math.cos(i * angle);
                o.y = c.y + c.r * 0.6 * Math.sin(i * angle);
                // eslint-disable-next-line fp/no-mutating-methods
                points.push(o);
              }

              context.beginPath();
              context.arc(c.x, c.y, c.r, 0, 2 * Math.PI, false);
              context.closePath();

              context.lineWidth = 3;
              context.strokeStyle = '#fff';
              context.stroke();

              // make play button
              context.fillStyle = '#fff';

              context.beginPath();
              context.moveTo(points[0].x, points[0].y);
              for (let i = 1; i < points.length; i += 1) {
                context.lineTo(points[i].x, points[i].y);
              }
              context.fill();
              context.closePath();

              context.lineWidth = 3;
              context.lineJoin = 'round';
              context.strokeStyle = '#fff';
              context.stroke();

              const screenSnapUrl = canvas.toDataURL('image/png');

              fileUpload = {
                ...fileUpload,
                postFilePreview: screenSnapUrl,
                postFile: fileUpload.name,
                ext: getExt(fileUpload.name),
                type: fileUpload.type,
              };

              video.removeEventListener('canplay', snapshot);
            };

            fileUpload.postFilePublic = url;

            video.addEventListener('canplay', snapshot);
            video.src = url;
          }
          break;

        default:
          fileUpload.postFilePreview = placeholderPngSrc;
      }

      setUploads(prevState => ({
        ...prevState,
        [fileUpload.uniqFileName]: {
          ...prevState[fileUpload.uniqFileName],
          ...fileUpload,
        },
      }));
    }
  }, []);

  const onFileError = (file, newError) =>
    setUploads(prevState => ({
      ...prevState,
      [file.uniqFileName]: {
        ...prevState[file.uniqFileName],
        postFilePreview: placeholderPngSrc,
        postFilePublic: null,
        postFile: file.name,
        ext: getExt(file.name),
        type: file.type,
        error: newError,
      },
    }));

  const onFileDelete = file =>
    setUploads(prevState => {
      const DeletedUploads = {...prevState};
      // eslint-disable-next-line fp/no-delete
      delete DeletedUploads[file.uniqFileName];
      return DeletedUploads;
    });

  const setExtensions = value => {
    setExtended(value.length > 0);
  };

  const onSubmit = event => {
    event.preventDefault();
    const id = db.createUUID();
    const {uid} = authUser;

    const mentions = (text || '').split('@[').map((str = '') => {
      if (str.length === 0) return {text: ''};
      if (str.split('](').length === 1) return {text: str};
      const name = str.split('](')[0];
      const newId = str.split('](')[1].split(')')[0];
      return {
        name,
        id: newId,
        text: str.replace(name, '').replace(newId, '').replace(']()', ''),
      };
    });
    const mentionedFiltered = mentions.filter(obj => obj.id);
    const filteredSelectedTeamMembers = mentionedFiltered.length > 0 ? {} : null;
    mentionedFiltered.forEach(obj => {
      filteredSelectedTeamMembers[obj.id] = true;
    });
    // Determine the goals that were used given the selected steps.
    // Better to "compute" this here than try and keep it in sync
    // whilst selecting steps
    const selectedGoalsObj = Object.entries(activeProfile.goals || {}).reduce((accum, [currGoalKey, currGoalValue]) => {
      if (Object.keys(currGoalValue.steps || {}).some(goalId => selectedSteps[goalId])) {
        // eslint-disable-next-line no-param-reassign
        accum[currGoalKey] = true;
        return accum;
      }
      return accum;
    }, {});

    const newUploads = Object.values(uploads).map(({postFilePublic, postFilePublicLqip = null, postFile, storagePath, ext, type}) => ({
      storagePath,
      postFilePublicLqip,
      postFilePublic,
      postFile,
      ext,
      type,
    }));

    const onlySelectedSteps = Object.entries(selectedSteps).reduce((nextSteps, [stepId, isSelected]) => {
      if (isSelected) {
        return {
          ...nextSteps,
          [stepId]: true,
        };
      }
      return nextSteps;
    }, {});

    // Create a new post
    db.createPost({
      id,
      profileId: activeProfile.id,
      authorId: uid,
      text,
      steps: onlySelectedSteps,
      goals: selectedGoalsObj,
      members: filteredSelectedTeamMembers,
      uploads: newUploads,
    })
      .then(() => {
        if (onboard) {
          // eslint-disable-next-line fp/no-mutating-methods
          return history.push(
            routes.generate(routes.profile.onboard.done, {
              profileId: activeProfile.id,
            })
          );
        }

        // Reset the form state but keep the active profile set.
        setActiveProfile(activeProfile);
        setSelectedSteps({});
        // setSelectedTeamMembers({});
        setText('');

        setUploads({});
        setExtended(false);
        // setOpenTags(false);
        setTags([]);
        setIsViewingProfile(false);
        setIsUploading(false);

        // eslint-disable-next-line fp/no-mutating-methods
        history.push(
          routes.generate(routes.profile.home, {
            profileId: activeProfile.id,
          })
        );

        return true;
      })
      .catch(e => {
        errorLogger(e);
        setError(e);
      });
  };

  const renderGoals = () => {
    if (!activeProfile || !activeProfile.goals) return null;
    const selected = Object.entries(selectedSteps)
      .map(([id, step]) => ({id, step}))
      .filter(obj => obj.step);
    return (
      <GoalSelection
        profileId={activeProfile.id}
        goals={activeProfile.goals}
        handleClickStep={handleClickStep}
        selectedSteps={selectedSteps}
        selectedFiltered={selected}
      />
    );
  };

  const renderUserSuggestion = (entry, search, highlightedDisplay, index, focused) => (
    <>
      <Avatar className="comments-textarea__suggestions__item__image" imageClassName="img" src={entry.profileImagePublic} />
      <div className="comments-textarea__suggestions__item__text">{highlightedDisplay}</div>
    </>
  );

  if (isViewer) return null;

  return (
    <div className="post-create">
      <div className="heading -primary">
        <h4>Create a post</h4>
      </div>
      <form onSubmit={onSubmit} className="post-create">
        <section className="section">
          <MentionsInput
            className="comments-textarea"
            value={text}
            placeholder="Share something... use @ to tag someone"
            onChange={event => {
              setExtensions(event.target.value);
              setText(event.target.value);
            }}>
            <Mention trigger="@" data={tags} renderSuggestion={renderUserSuggestion} className="comments-textarea-tagged" />
          </MentionsInput>

          {profilesStore.profiles.length > 1 && !isViewingProfile && (
            <div className="select">
              <select onChange={handleUpdateSelectedProfile} value={activeProfile?.id}>
                {profilesStore.profiles.map(({id, firstname, lastname}) => {
                  if (id) {
                    return (
                      <option key={id} value={id}>
                        {firstname} {lastname}
                      </option>
                    );
                  }
                  return null;
                })}
              </select>
            </div>
          )}
        </section>

        <PoseGroup>
          {extended && (
            <PosedSection className="extensions" key="extensions">
              <div className="goals-select">{renderGoals()}</div>
            </PosedSection>
          )}
        </PoseGroup>

        {uploads && Object.values(uploads).length > 0 && (
          <section className="section">
            <h4>Attachments</h4>
            <div className="uploads">
              {Object.values(uploads).map((upload, idx) => {
                const [type] = (upload && upload.type && upload.type.split('/')) || '';
                return (
                  <div key={idx} className={`upload -${type}`}>
                    {type === 'image' && (
                      <a href={upload.postFilePublic} className="upload-image" target="_blank" rel="noopener noreferrer">
                        <img src={upload.postFilePreview} alt={upload.postFile} />
                      </a>
                    )}
                    {type === 'video' && (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      <video controls className="upload-video">
                        <source src={upload.postFilePublic} type={upload.type} />
                        <a className="upload-video" href={upload.postFilePublic}>
                          <img src={upload.postFilePreview} alt={upload.postFile} />
                        </a>
                      </video>
                    )}
                    {type !== 'image' && type !== 'video' && (
                      <a href={upload.postFilePublic} className="upload-file" target="_blank" rel="noopener noreferrer">
                        <FileIcon extension={upload.ext} {...defaultStyles[upload.ext]} />
                        <span className="upload-filename" title={upload.postFile}>
                          {upload.postFile}
                        </span>
                      </a>
                    )}
                    {(upload.done || upload.progress >= 100) && (
                      <button
                        type="button"
                        className="upload-delete"
                        onClick={e => {
                          e.preventDefault();
                          onFileDelete(upload);
                        }}>
                        <Icon type="minus-circled" />
                      </button>
                    )}
                    {!upload.done && 'progress' in upload && upload.progress < 100 && <Progress percent={upload.progress} />}
                  </div>
                );
              })}
            </div>
          </section>
        )}

        <section className="footer-action">
          {onCancel && (
            <Button type="tertiary" left onClick={onCancel}>
              Cancel
            </Button>
          )}
          <FileUpload
            inputId={`create_post_file_${activeProfile?.uid}`}
            inputName={`create_post_file_${activeProfile?.uid}`}
            onComplete={onFileUpload}
            onProgress={onFileProgress}
            onError={onFileError}
            uploadIcon="attach"
            filePath={`/uploads/posts/${activeProfile?.id}/`}
          />
          <Button submit type="primary" right loading={isUploading}>
            Post
          </Button>
          {error && <p>{error.message}</p>}
        </section>
      </form>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(withRouter(inject('stores')(observer(PostCreate))));

const getActiveProfileFromParams = props =>
  props.stores.profilesStore.profiles.find(p => p.id === props.match.params.profileId) || props.stores.profilesStore.profiles[0];
