/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable fp/no-mutation */
/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {uploadFile} from '../../firebase/db';
import {errorLogger} from '../../helpers/errorLogger';

import {Icon} from '../common';

function FileUpload({inputId, inputName, uploadIcon, filePath, onProgress, onError, onComplete, onRetryError}) {
  const [uploadPercentage, setUploadPercentage] = useState({});
  const [uploadControl, setUploadControl] = useState({});
  const [errors, setErrors] = useState(false);
  const [uploading, setUploading] = useState(false);

  const readFile = file =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => resolve(e.target.result);
      reader.readAsDataURL(file);
    });

  const createImage = data =>
    new Promise(resolve => {
      const img = document.createElement('img');
      img.onload = () => resolve(img);
      img.src = data;
    });

  const rotate = (type, img) =>
    new Promise(resolve => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.transform(1, 0, 0, 1, 0, 0);
      ctx.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob(resolve, type);
    });

  const handleFileUpload = e => {
    // Uploading files...

    // Start the progressbar before the first callback
    const newUploadPercentage = {
      ...uploadPercentage,
    };

    const newUploadControl = {
      ...uploadControl,
    };

    const files = Array.from(e.target.files).map(file => {
      // eslint-disable-next-line no-param-reassign
      file.uniqFileName = `${Date.now() + Math.random()}-${file.name}`;

      // Initialise at 0 percent.
      newUploadPercentage[file.uniqFileName] = 0;

      return file;
    });

    setUploadPercentage(newUploadPercentage);
    setUploading(true);

    // Read the file, rotate, convert to blob and upload to server.
    files.forEach(async file => {
      let blob = file;

      if (file.type.match(/image.*/)) {
        const imageFile = await readFile(file);
        const image = await createImage(imageFile);

        blob = await rotate(file.type, image);
        blob.name = file.name;
        blob.uniqFileName = file.uniqFileName;
      }

      newUploadControl[file.uniqFileName] = uploadFile(`${filePath}${file.uniqFileName}`, blob, handleProgress, handleError, handleComplete);
    });

    setUploadControl(newUploadControl);
  };

  const handleProgress = (file, percentage) => {
    setUploadPercentage({
      ...uploadPercentage,
      [file.uniqFileName]: percentage,
    });

    const controls = uploadControl[file.uniqFileName];

    onProgress(file, percentage, controls);
  };

  const handleError = (file, err) => {
    errorLogger(err);

    setErrors({
      ...errors,
      [file.uniqFileName]: err,
    });

    setUploadPercentage({
      ...uploadPercentage,
      [file.uniqFileName]: 0,
    });

    onError(file, err);
  };

  const handleComplete = (file, url, newFilePath) => {
    const incomplete = Object.values(uploadPercentage).find(percent => percent < 100);

    if (!incomplete) setUploadPercentage(false);

    setUploading(false);
    onComplete(file, url, newFilePath);
  };

  const handleRetryError = e => {
    e.preventDefault();
    onRetryError();
  };

  return (
    <div className="file-upload">
      {errors && (
        <span className="file-upload__error">
          Error uploading file
          <button type="button" onClick={handleRetryError} className="button -primary-light">
            Retry
          </button>
        </span>
      )}
      {!uploading && (
        <span>
          <input
            type="file"
            onChange={handleFileUpload}
            name={inputName || `file_upload_name`}
            id={inputId || `file_upload_id`}
            className="inputfile"
            multiple
          />
          <label htmlFor={inputName || `file_upload_name`} className="button -primary-light">
            <Icon type={uploadIcon || 'upload-cloud'} size={24} />
          </label>
        </span>
      )}
      {uploading && (
        <button type="button" className="button -primary-light file-progress" disabled>
          <i className="icon-cw animate-spin" size={24} />
        </button>
      )}
    </div>
  );
}

export default FileUpload;
