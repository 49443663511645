/* eslint-disable fp/no-mutation */
/* eslint-disable react/prop-types */
import React, {useCallback, useContext, useState, useEffect, useMemo} from 'react';
import {observer, inject} from 'mobx-react';
import {v1 as uuid} from 'uuid';
import {isEmpty, capitalize, map, difference, omit} from 'lodash';

import {setChonkyDefaults, ChonkyActions, FileBrowser, FileContextMenu, FileList, FileNavbar, FileToolbar, FileHelper} from 'chonky';
import {ChonkyIconFA} from 'chonky-icon-fontawesome';
import ProfileAside from './profile.aside';
import {Card} from '../common';
import getIsOwner from '../../helpers/getIsOwner';
import getIsViewer from '../../helpers/getIsViewer';
import FileUpload from '../file-upload/file-upload';
import AuthUserContext from '../session/AuthUserContext';
import {createDocument, updateDocumentVisibility, removeDocument, downloadDocument} from '../../firebase/db';

import '../../assets/scss/components/documents/documents.scss';

// eslint-disable-next-line no-bitwise
const getExt = fname => fname.slice(((fname.lastIndexOf('.') - 1) >>> 0) + 2);

const useOnFileUploadCallbacks = (type, profileId, currentFolderId = 'root', onFileChange = null) => {
  const [uploadingDocuments, setUploadingDocuments] = useState({});
  const authUser = useContext(AuthUserContext);

  const onFileUploaded = useCallback(
    (file, url) => {
      const docToCreate = {
        id: file.uuid,
        profileId,
        authorId: authUser.uid,
        created_at: Date.now(),
        description: '',
        ext: getExt(file.name),
        filelink: url,
        filename: file.name,
        visibility: type,
        metadata: {
          color: type === 'owner' ? '#1383a4' : '#00a694',
          id: file.uuid,
          modDate: Date.now(),
          name: file.name,
          parentId: currentFolderId,
          profileId,
        },
      };

      createDocument(docToCreate);

      // Ignore 'deleted' not used linter.
      // eslint-disable-next-line
      const {[file.uuid]: deleted, nextUploadingDocuments = {}} = uploadingDocuments;

      setUploadingDocuments(nextUploadingDocuments);
      onFileChange(docToCreate);
    },
    [profileId, authUser.uid, type, currentFolderId, uploadingDocuments, onFileChange]
  );

  const onFileProgress = useCallback(
    (file, percentComplete, controls) => {
      if (!file.uuid) {
        // eslint-disable-next-line fp/no-mutation, no-param-reassign
        file.uuid = uuid();
      }

      setUploadingDocuments({
        ...uploadingDocuments,
        [file.uuid]: {
          file,
          percentComplete,
          filename: file.name,
          ext: getExt(file.name),
          saving: false,
          error: false,
          controls,
        },
      });
    },
    [uploadingDocuments, setUploadingDocuments]
  );

  const onFileError = useCallback(
    (file, error) => {
      setUploadingDocuments({
        ...uploadingDocuments,
        [file.uuid]: {
          file,
          filename: file.name,
          ext: getExt(file.name),
          percentComplete: 0,
          saving: false,
          error,
        },
      });
    },
    [uploadingDocuments]
  );

  const onCancelUpload = useCallback(
    cancelledUuid => {
      const {[cancelledUuid]: deleted, ...nextUploadingDocuments} = uploadingDocuments;

      if (!deleted) return;
      if (deleted.controls) deleted.controls.cancel();

      setUploadingDocuments(nextUploadingDocuments);
    },
    [uploadingDocuments, setUploadingDocuments]
  );

  return [Object.values(uploadingDocuments), onFileProgress, onFileUploaded, onFileError, onCancelUpload];
};

const rootDirectoryName = 'root';

export function MyFileBrowser({type, documents, profileId, onCurrentFolderId, onFileChange}) {
  const moveTo = useMemo(
    () => ({
      id: `move_to_${type === 'owner' ? 'team' : 'owner'}`,
      requiresSelection: true,
      button: {
        name: `Move to ${type === 'owner' ? 'team' : 'owner'} documents`,
        toolbar: true,
        contextMenu: true,
        group: 'Actions',
        icon: ChonkyActions.CopyFiles.button.icon,
      },
    }),
    [type]
  );
  const fileActions = [ChonkyActions.CreateFolder, ChonkyActions.DownloadFiles, ChonkyActions.DeleteFiles, moveTo];
  const [rootFileMap, setRootFileMap] = useState({});
  const [currentFileMap, setCurrentFileMap] = useState([]);
  const [currentFolderId, setCurrentFolderId] = useState(rootDirectoryName);
  const [folderChain, setFolderChain] = useState([]);
  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    let rootIds = [];

    const files = Object.keys(documents)
      .map(id => {
        const document = documents[id];
        const color = type === 'owner' ? '#1383a4' : '#00a694';

        if (document.isDir) {
          const childrenIds = Object.keys(documents)
            .filter(childId => {
              const child = documents[childId];
              return child.parentId === document.id;
            })
            .reduce((result, key) => [...result, documents[key].id], []);

          return {
            ...document,
            id: document.id,
            name: document.name ? document.name : document.filename,
            modDate: document.modDate ? document.modDate : document.created_at,
            color: document.isDir ? '#ff583c' : color,
            parentId: document.parentId ? document.parentId : rootDirectoryName,
            childrenIds,
            childrenCount: childrenIds.length,
          };
        }

        let name = document.name ? document.name : document.filename;
        if (document.description) {
          name += ` (${document.description}) `;
        }

        return {
          ...document,
          id: document.id,
          name,
          modDate: document.modDate ? document.modDate : document.created_at,
          color: document.isDir ? '#ff583c' : color,
          parentId: document.parentId ? document.parentId : rootDirectoryName,
        };
      })
      .filter(doc => {
        const isValid = doc.visibility === type;
        if (isValid) rootIds = [...rootIds, doc.id];
        return isValid;
      })
      .reduce((result, element) => {
        // eslint-disable-next-line no-param-reassign
        result[element.id] = {};
        Object.keys(element).forEach(k => {
          // eslint-disable-next-line no-param-reassign, fp/no-mutating-assign
          result[element.id] = Object.assign(result[element.id], {[k]: element[k]});
        });
        return result;
      }, {});

    const fileMap = {
      [rootDirectoryName]: {
        id: rootDirectoryName,
        name: `${capitalize(type)} Documents`,
        isDir: true,
        childrenIds: rootIds,
        childrenCount: rootIds.length,
      },
      ...files,
    };

    setCurrentFolderId(rootDirectoryName);
    setRootFileMap({
      rootFolderId: rootDirectoryName,
      fileMap,
    });
  }, [documents, type]);

  useEffect(() => {
    if (!isEmpty(rootFileMap) && currentFolderId) {
      const currentFolder = rootFileMap.fileMap[currentFolderId];
      const newFolderChain = [currentFolder];
      const childrenIds = currentFolder.childrenIds || [];
      const newCurrentFileMap = childrenIds.map(fileId => rootFileMap.fileMap[fileId]).filter(file => file?.parentId === currentFolderId);

      if (childrenIds) setCurrentFileMap(newCurrentFileMap);

      let {parentId} = currentFolder;

      while (parentId) {
        const parentFile = rootFileMap.fileMap[parentId];
        if (parentFile) {
          // eslint-disable-next-line fp/no-mutating-methods
          newFolderChain.unshift(parentFile);
          parentId = parentFile.parentId;
        } else {
          break;
        }
      }

      onCurrentFolderId(currentFolderId);
      setFolderChain(newFolderChain);
    }
  }, [currentFolderId, onCurrentFolderId, rootFileMap]);

  const [, , , , onCancelUploadOwner] = useOnFileUploadCallbacks('owner', profileId, currentFolderId);
  const [, , , , onCancelUploadTeam] = useOnFileUploadCallbacks('team', profileId, currentFolderId);

  const onDocumentDelete = useCallback(
    files => {
      const deletedIds = map(files, 'id');
      // eslint-disable-next-line no-unsafe-optional-chaining
      const childrenIds = [...rootFileMap?.fileMap[currentFolderId]?.childrenIds];
      const newChildrenIds = difference(childrenIds, deletedIds);

      // Remove file from file browser
      const rest = omit(rootFileMap.fileMap, deletedIds);
      const currentFolder = rest[currentFolderId];
      const newCurrentFolder = {...currentFolder, childrenIds: newChildrenIds};
      setRootFileMap({fileMap: {...rest, [currentFolderId]: newCurrentFolder}});

      // Remove file from database.
      deletedIds.forEach(id => {
        removeDocument(profileId, id);
        onCancelUploadOwner(id);
        onCancelUploadTeam(id);
      });
    },
    [rootFileMap.fileMap, currentFolderId, profileId, onCancelUploadOwner, onCancelUploadTeam]
  );

  const onFolderCreate = useCallback(
    folderName => {
      const {uid} = authUser;

      // Create the new folder
      const newFolderId = uuid();
      const newFolder = {
        id: newFolderId,
        name: folderName,
        isDir: true,
        modDate: new Date(),
        parentId: currentFolderId,
        childrenIds: [],
        childrenCount: 0,
        color: '#ff583c',
        visibility: type,
      };

      // Update parent folder to reference the new folder.
      const parentFolder = rootFileMap.fileMap[currentFolderId];
      const newParentFolder = {...parentFolder, childrenIds: [...parentFolder.childrenIds, newFolderId]};

      // Update root file map to include the new folder.
      const newFileMap = {
        ...rootFileMap.fileMap,
        [currentFolderId]: newParentFolder,
        [newFolderId]: newFolder,
      };
      setRootFileMap({...rootFileMap, fileMap: newFileMap});

      // Update current file map to include the new folder.
      const {childrenIds} = newParentFolder;
      setCurrentFileMap(childrenIds.map(fileId => newFileMap[fileId]));

      // Store in DB
      createDocument({
        id: newFolder.id,
        profileId,
        authorId: uid,
        filename: '',
        filelink: '',
        ext: '',
        description: '',
        visibility: type,
        metadata: newFolder,
      });
    },
    [currentFolderId, type, rootFileMap, profileId, authUser]
  );

  const onFileMove = useCallback(
    ({payload}) => {
      const {destination, files} = payload;
      const newRootFileMap = {...rootFileMap};

      // Move file to new folder
      files.forEach(element => {
        const fileId = element.id;
        const file = newRootFileMap.fileMap[fileId];
        const destinationId = destination.id;
        const destinationFolder = newRootFileMap.fileMap[destinationId];
        const newDestinationChildrenIds = [...destinationFolder.childrenIds, fileId];
        const originId = element.parentId;
        const originFolder = newRootFileMap.fileMap[originId];
        const newOriginChildrenIds = originFolder.childrenIds.filter(e => e !== fileId);

        const metadata = {
          color: element.color,
          id: fileId,
          modDate: Date.now(),
          name: element.name,
          parentId: destinationId,
          profileId,
        };

        // Update rootFileMap which is responsibly for the displaying of files.
        newRootFileMap.fileMap[fileId] = {...file, ...metadata};
        newRootFileMap.fileMap[destinationId] = {
          ...destinationFolder,
          childrenIds: newDestinationChildrenIds,
          childrenCount: newDestinationChildrenIds.length,
        };
        newRootFileMap.fileMap[originId] = {
          ...originFolder,
          childrenIds: newOriginChildrenIds,
          childrenCount: newOriginChildrenIds.length,
        };

        // Store in DB
        createDocument({...element, profileId, metadata});
      });

      // Update root file map to include the new folder.
      setRootFileMap(newRootFileMap);
    },
    [profileId, rootFileMap]
  );

  const onFileDownload = useCallback(({state}) => {
    const {selectedFilesForAction} = state;
    selectedFilesForAction.forEach(({filelink, filename}) => {
      // Download in DB
      downloadDocument(filelink, filename);
    });
  }, []);

  const handleAction = ({id, payload, state}) => {
    if (id === ChonkyActions.OpenFiles.id) {
      const {targetFile, files} = payload;
      const fileToOpen = targetFile ?? files[0];

      if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
        // Open the folder
        setCurrentFolderId(fileToOpen.id);
      } else {
        // Open the files
        files.forEach(element => {
          if (element.filelink) window.open(element.filelink, '_blank');
        });
      }
    } else if (id === ChonkyActions.DeleteFiles.id) {
      // Delete the files
      onDocumentDelete(state?.selectedFiles);
    } else if (id === ChonkyActions.CreateFolder.id) {
      // Create folder
      // eslint-disable-next-line no-alert
      const folderName = prompt('Provide the name for your new folder:');
      if (folderName) onFolderCreate(folderName);
    } else if (id === ChonkyActions.MoveFiles.id) {
      // Move file
      onFileMove({payload});
    } else if (id === ChonkyActions.DownloadFiles.id) {
      // Download files
      onFileDownload({state});
    } else if (id.includes('move_to_')) {
      state.selectedFilesForAction.forEach(obj => {
        updateDocumentVisibility(obj.id, obj.profileId, id.replace('move_to_', ''));
        setTimeout(() => onFileChange(), 500);
      });
    }
  };

  return (
    <div className="file-browser" style={{height: 135 * Math.ceil(Math.max(4, currentFileMap.length || 0) / 4) + 120}}>
      <FileBrowser
        files={currentFileMap}
        folderChain={folderChain}
        fileActions={fileActions}
        onFileAction={handleAction}
        defaultFileViewActionId={ChonkyActions.EnableListView.id}
        disableDefaultFileActions={[ChonkyActions.SortFilesBySize.id, ChonkyActions.ToggleHiddenFiles.id]}>
        <FileNavbar />
        <FileToolbar />
        <FileList />
        <FileContextMenu />
      </FileBrowser>
    </div>
  );
}

// const ProfileDocuments = ({ profileId, profile, documents }) => {
function ProfileDocuments({profileId, profilesStore}) {
  const [isOwner, setIsOwner] = useState({});
  const [isViewer, setIsViewer] = useState({});
  const [documents, setDocuments] = useState({});
  const [currentFolderId, onCurrentFolderId] = useState(rootDirectoryName);

  useEffect(() => {
    const profile = profilesStore.getProfileById(profileId);
    setDocuments(profile.documents);
    setIsOwner(getIsOwner(profile));
    setIsViewer(getIsViewer(profile));
  }, [profileId, profilesStore]);

  const onFileChange = useCallback(() => {
    const profile = profilesStore.getProfileById(profileId);
    setDocuments(profile.documents);
  }, [profilesStore, profileId]);

  const [, onFileProgressOwner, onFileUploadedOwner, onFileErrorOwner] = useOnFileUploadCallbacks('owner', profileId, currentFolderId, onFileChange);

  const [, onFileProgressTeam, onFileUploadedTeam, onFileErrorTeam] = useOnFileUploadCallbacks('team', profileId, currentFolderId, onFileChange);

  setChonkyDefaults({iconComponent: ChonkyIconFA});

  return (
    <div className="container">
      <aside className="aside">
        <ProfileAside showProgress={!isViewer} profileId={profileId} />
      </aside>
      <div className="bside">
        <Card className="documents" feed>
          {isOwner && (
            <div className="goal-single owner-documents">
              <div className="goal-header documents__heading">
                <div className="title">
                  <h3>Owner Documents</h3>
                  <p>Visible to owners only</p>
                </div>

                <div className="action">
                  <FileUpload
                    inputId={`profile_document_owner_file_${profileId}`}
                    inputName={`profile_document_owner_file_${profileId}`}
                    onComplete={onFileUploadedOwner}
                    onProgress={onFileProgressOwner}
                    onError={onFileErrorOwner}
                    filePath={`/uploads/documents/${profileId}/`}
                  />
                </div>
              </div>

              <MyFileBrowser type="owner" documents={documents} profileId={profileId} onCurrentFolderId={onCurrentFolderId} onFileChange={onFileChange} />
            </div>
          )}
        </Card>

        <Card className="documents" feed>
          <div className="goal-single team-documents">
            <div className="goal-header documents__heading">
              <div className="title">
                <h3>Team Documents</h3>
                <p>Visible to team members</p>
              </div>

              <div className="action">
                <FileUpload
                  inputId={`profile_document_team_file_${profileId}`}
                  inputName={`profile_document_team_file_${profileId}`}
                  onComplete={onFileUploadedTeam}
                  onProgress={onFileProgressTeam}
                  onError={onFileErrorTeam}
                  filePath={`/uploads/documents/${profileId}/`}
                />
              </div>
            </div>

            <MyFileBrowser type="team" documents={documents} profileId={profileId} onCurrentFolderId={onCurrentFolderId} onFileChange={onFileChange} />
          </div>
        </Card>
      </div>
    </div>
  );
}

// This is a workaround... react hook based function's don't like to be
// directly made reactive. Passing observed props down achieves the same thing.
function Observe(props) {
  const {
    stores: {profilesStore},
    match,
    history,
  } = props;
  const {profileId} = match.params;

  // With mobx you need to "use" the variables you want to observe. In this case, that means reading the profile.documents.
  // return <ProfileDocuments profileId={profileId} profile={profile} documents={profile.documents} history={history} />;
  return <ProfileDocuments profileId={profileId} profilesStore={profilesStore} history={history} />;
}

export default inject('stores')(observer(Observe));
