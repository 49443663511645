/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, {useMemo, useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {observer} from 'mobx-react';
import {isEqual, debounce} from 'lodash';
import {RiArrowUpDownLine} from 'react-icons/ri';
import Confetti from 'react-dom-confetti';
import routes from '../../constants/routes';

import {db} from '../../firebase';
import withProfile from '../profile/withProfile';
import getIsOwner from '../../helpers/getIsOwner';
import getIsViewer from '../../helpers/getIsViewer';

import GoalSteps from './goal.steps';

function Goal({profileId, profile, goalId, goal, selectedSteps, mode = 'screen', showOnly}) {
  const [steps, setSteps] = useState(goal?.steps ?? []);
  const [listItems, setListItems] = useState([]);
  const [goalCompleted, setGoalCompleted] = useState(!!goal.complete);
  const [completeSteps, setCompleteSteps] = useState([]);
  const [totalStars, setTotalStars] = useState([]);
  const isOwner = getIsOwner(profile);
  const isViewer = getIsViewer(profile);
  const [showSteps] = useState(true);

  const confettiConfig = {
    angle: 90,
    spread: 45,
    startVelocity: 45,
    elementCount: 50,
    decay: 0.9,
  };

  const goalStyles = useMemo(() => `goal-single${goalCompleted ? ' -complete' : ''}`, [goalCompleted]);

  const fetchCounts = useCallback(async (fetchProfileId, fetchSteps) => {
    const starCounts = {};
    const stepsKeys = Object.keys(fetchSteps);
    const counts = await Promise.all(stepsKeys.map(stepId => db.getStepStarCount(fetchProfileId, stepId)));

    // eslint-disable-next-line no-return-assign, fp/no-mutation
    stepsKeys.forEach((stepId, ix) => (starCounts[stepId] = counts[ix]));

    const newTotalStars = Object.values(starCounts).reduce((prev, curr) => prev + curr, 0);
    setTotalStars(newTotalStars);

    // eslint-disable-next-line fp/no-mutating-methods
    const newListItems = stepsKeys.map((id, index) => ({...fetchSteps[id], id, count: counts[index]})).sort((a, b) => a.sort - b.sort);
    setListItems(newListItems);
  }, []);

  const updateCounts = useCallback(async (updatedProfileId, updatedSteps) => {
    const stepValues = updatedSteps ? Object.values(updatedSteps) : [];
    if (updatedSteps) setCompleteSteps(stepValues.filter(s => s.complete));
  }, []);

  useEffect(() => {
    updateCounts(profileId, steps);
    fetchCounts(profileId, steps);
  }, [fetchCounts, profileId, steps, updateCounts]);

  const handleGoalToggleComplete = useCallback(() => {
    db.saveGoal(profileId, goal.id, {complete: !goalCompleted});
    setGoalCompleted(prev => !prev);
  }, [profileId, goal, goalCompleted]);

  const handleStepToggleComplete = useCallback(
    (completedGoalId, stepId) => {
      // eslint-disable-next-line fp/no-mutation
      steps[stepId].complete = !steps[stepId].complete;

      db.saveStep(profileId, completedGoalId, stepId, {...steps[stepId]});

      setSteps(steps);
      updateCounts(profileId, steps);
    },
    [profileId, steps, updateCounts]
  );

  const handleListItemsChange = debounce(changedSteps => {
    if (!isEqual(listItems, changedSteps)) {
      changedSteps.map((step, index) => {
        db.saveStep(profileId, goal.id, step.id, {complete: step.complete, text: step.text, sort: index});
        return null;
      });
    }
  }, 1000);

  if (!goal) return null;

  return (
    <div className={goalStyles}>
      <div className="goal-header">
        {!isViewer && completeSteps.length === Object.keys(steps || {}).length && (
          <div className="toggle">
            <span className={`button -checkbox ${goalCompleted ? '-complete' : ''}`} onClick={handleGoalToggleComplete}>
              <i className="icon-check" />
            </span>
            <Confetti active={goalCompleted} config={confettiConfig} />
          </div>
        )}
        <div className="title">
          <h3>{goal.name}</h3>
        </div>
        <div className="meta">
          <div className="item -primary">
            <i className="icon-star" /> {totalStars}
          </div>
          <div className="item -secondary">
            <i className="icon-check" /> {`${completeSteps.length}/${Object.keys(steps).length}`}
          </div>
        </div>
        {!isViewer && (
          <div className="action">
            <Link
              to={routes.generate(routes.profile.goal.detail, {
                profileId,
                goalId,
              })}
              className="button -tertiary -tiny">
              Details
            </Link>
            <RiArrowUpDownLine className="draggable" />
          </div>
        )}

        {mode === 'pdf' && <div className="description">{goal.description}</div>}
      </div>

      <div className={`goal-steps${showSteps ? '' : '__hidden'}`}>
        {listItems.length > 0 && (
          <GoalSteps goalId={goalId} listItems={listItems} onToggleComplete={handleStepToggleComplete} onListItemsChange={handleListItemsChange} />
        )}

        {isOwner && !showOnly && (
          <Link
            className="step-add"
            to={routes.generate(routes.profile.goal.edit, {
              profileId,
              goalId,
            })}>
            <div className="action">
              <span className="button -toggle -primary">
                <i className="icon-plus" />
              </span>
            </div>
            <div className="content">
              <h4>Add a step</h4>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
}

export default withProfile(observer(Goal));
