import validate from 'validate.js';
import dayjs from 'dayjs';

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse(value, options) {
    return +dayjs(value).valueOf();
  },
  // Input is a unix timestamp
  format(value, options) {
    const format = options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss';
    return dayjs(value).format(format);
  },
});

export const validateTerms = value => (!value ? 'You must agree to the Terms & Conditions' : undefined);
export const validateEmail = value => validate.single(value, {presence: true, email: true});
export const validateRequired = value => validate.single(value, {presence: true});
export const validatePassword = value =>
  validate.single(value, {
    presence: true,
    length: {
      minimum: 6,
      message: 'must be at least 6 characters',
    },
  });
export const validateMobile = value =>
  validate.single(value, {
    presence: true,
    length: {
      minimum: 10,
      message: 'must be at least 10 digits',
    },
  });
export const validateTimestamp = value => validate.single(value, {presence: true, datetime: true});
export const validateDate = value => validate.single(value, {presence: true, datetime: {dateOnly: true}});

export default validate;
