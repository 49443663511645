/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';

import {Card, Button} from '../common';
import {db} from '../../firebase';
import routes from '../../constants/routes';
import config from '../../constants/config';
import withAuthorization from '../session/withAuthorization';
import {getUserById} from '../../firebase/db';

function ProfileCreate({authUser}) {
  const [uid, setUid] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [self, setSelf] = useState(false);
  const [description] = useState('');
  const [relationship, setRelationship] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setUid(authUser.uid);
  }, [authUser]);

  const onSubmit = async event => {
    event.preventDefault();

    const id = db.createUUID();

    // Check for blank profile names
    if (!firstname || firstname === '') {
      const nameError = {message: 'Profiles must have a first name'};
      setError(nameError);
      return;
    }

    setLoading(true);

    try {
      // Create a new profile
      await db.createProfile(id, firstname, lastname, self, description);

      // Grant permissions to this user on the profile
      await db.updatePermissions(id, uid, 'owner', 'family', relationship);
      await db.adjustUserPermissions({
        memberId: config.sameviewTeamUid,
        type: 'professional',
        relationship: 'Support',
        permissionLevel: 'owner',
        profileId: id,
      });

      // Create a new plan with a trial subscription.
      const plan = await db.getStripePlanId();
      const customer = await db.getStripeCustomerId(uid);

      // Create or edit the subscription plan.
      const subscriptionData = {
        cancel_at_period_end: false,
        coupon: '',
        customer,
        id: '',
        item: '',
        order_id: id,
        plan,
        trial_from_plan: true,
        user_id: uid,
      };

      // Save the subscription to the database
      await db.updateStripeSubscriptions(id, subscriptionData);

      // Navigate to the onboarding
      // eslint-disable-next-line consistent-return, no-return-assign, fp/no-mutation
      return (window.location = routes.generate(routes.profile.onboard.intro, {
        profileId: id,
      }));
    } catch (submitError) {
      setError(submitError);
      setLoading(false);
    }
  };

  const updateType = value => {
    setSelf(value);

    getUserById(authUser.uid).then(userData => {
      const userFirstName = userData.firstname || '';
      const userLastName = userData.lastname || '';
      // If the type changes, and the name is blank, fill in the name
      if (value === 'true' && !firstname && !lastname) {
        setFirstname(userFirstName);
        setLastname(userLastName);
        setRelationship('Me');
      } else {
        setRelationship('');
      }
    });
  };

  return (
    <div className="page -account">
      <div className="container">
        <Card focus>
          <div className="heading -primary">
            <h4>Create a profile</h4>
          </div>
          <form onSubmit={onSubmit}>
            <section className="section">
              <h3>{`I'm creating a profile for`}</h3>

              <div className="select">
                <select value={self} onChange={event => updateType(event.target.value)}>
                  <option value={false}>Someone else (e.g. my child)</option>
                  <option value>Myself</option>
                </select>
              </div>

              <label htmlFor="firstName">
                First name
                <input id="firstName" value={firstname} onChange={event => setFirstname(event.target.value)} type="text" placeholder="First Name" />
              </label>

              <label htmlFor="lastName">
                Last name
                <input id="lastName" value={lastname} onChange={event => setLastname(event.target.value)} type="text" placeholder="Last Name" />
              </label>

              {self !== 'true' && (
                <>
                  <label htmlFor="relationship">My relationship with {firstname || 'this person'}</label>
                  <input
                    id="relationship"
                    value={relationship}
                    onChange={event => setRelationship(event.target.value)}
                    type="text"
                    placeholder="E.g., Dad, Grandma, Aunt"
                  />
                </>
              )}
            </section>

            <section className="footer-action">
              <Button type="primary" loading={loading} right submit>
                Create Profile
              </Button>
            </section>

            {error && error.message && (
              <section className="article-errors">
                <div>{error.message}</div>
              </section>
            )}
          </form>
        </Card>
      </div>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(ProfileCreate)));
