/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import isEmpty from 'lodash/isEmpty';
import withAuthorization from '../session/withAuthorization';
import routes from '../../constants/routes';
import {useProfileCompletion} from '../../hooks';
import {Card} from '../common';
import PostCreate from '../post/create';
import getIsViewer from '../../helpers/getIsViewer';
import PinIcon from '../../assets/img/pin.png';

import '../../assets/scss/components/onboarding/displayProgress.scss';
import {getPinnedPosts, unmountPinnedPosts} from '../../firebase/db';
import {getTextFromPost} from '../../helpers/functionHelpers';

function ProfileAside({profileId, stores: {profilesStore, postsStore}, authUser, history}) {
  const [isShowProgress, setShowProgress] = useState(false);
  const profile = profilesStore.getProfileById(profileId);
  const [loading, , complete, {hasTeam, hasGoals, hasDetails, hasPosts}] = useProfileCompletion(profileId, profile, postsStore.posts);
  const [isViewer, setIsViewer] = useState('');
  const [pinnedPosts, setPinnedPosts] = useState(null);

  useEffect(() => {
    if (!profileId) {
      return;
    }
    getPinnedPosts(profileId, snapshot => {
      setPinnedPosts(snapshot.val() || []);
    });
    // eslint-disable-next-line consistent-return
    return () => unmountPinnedPosts(profileId);
  }, [loading, complete, profileId]);

  useEffect(() => {
    if (loading || complete || !profileId) {
      return;
    }

    if (!hasDetails) {
      setShowProgress({label: 'profile', route: routes.profile.onboard.detail});
      return;
    }

    if (!hasGoals) {
      setShowProgress({label: 'goals', route: routes.profile.onboard.goals});
      return;
    }

    if (!hasTeam) {
      setShowProgress({label: 'team', route: routes.profile.onboard.team});
      return;
    }
    setShowProgress(false);
  }, [loading, complete, hasTeam, hasGoals, hasDetails, hasPosts, profileId]);

  useEffect(() => {
    if (!isEmpty(profile)) setIsViewer(getIsViewer(profile));
  }, [profile]);

  return (
    <div className="profile-aside">
      {isShowProgress !== false && (
        <Card className="profile-aside-container">
          <div>
            <img src={PinIcon} width="11" height="14" alt="" />
          </div>
          <p className="profile-aside-text">
            Build and complete your{' '}
            <Link to={routes.generate(isShowProgress.route, {profileId})}>
              <strong className="profile-aside-text-green">{isShowProgress.label}</strong>
            </Link>
          </p>
        </Card>
      )}
      {!isViewer && (
        <Card full>
          <PostCreate />
        </Card>
      )}
      {pinnedPosts !== null && (
        <Card full>
          <div className="heading -secondary">
            <h4>Pinned posts</h4>
          </div>
          <div className="post-pinned">
            {Object.keys(pinnedPosts).map(post => {
              const text = getTextFromPost(pinnedPosts[post]);
              if (!text) {
                return null;
              }
              return (
                <Link className="pinned-post" key={post} to={routes.generate('/profile/:profileId/home/:postId', {profileId, postId: post})}>
                  {text}
                </Link>
              );
            })}
            {pinnedPosts.length === 0 && <span className="pinned-post">No pinned posts</span>}
          </div>
        </Card>
      )}
    </div>
  );
}

ProfileAside.propTypes = {
  profileId: PropTypes.string.isRequired,
};

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(withRouter(inject('stores')(observer(ProfileAside))));
