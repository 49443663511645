/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import {NotificationManager} from 'react-notifications';
import {inject, observer} from 'mobx-react';

import routes from '../../constants/routes';
import {inviteUser} from '../../firebase/db';
import withAuthorization from '../session/withAuthorization';
import getIsOwner from '../../helpers/getIsOwner';

import Invite from './invite';
import InviteFamilyForm from './invite.family';
import InviteProfessionalForm from './invite.professional';
import InviteFriendForm from './invite.friend';

import '../../assets/scss/components/invite/invite-card.scss';

function CardInvite(props) {
  const [inviteType, setInviteType] = useState(null);
  const [saving, setSaving] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  const {
    authUser,
    profile,
    onboard,
    history,
    onInvited,
    onCancel,
    heading,
    stores: {profilesStore},
  } = props;

  useEffect(() => {
    setIsOwner(getIsOwner(profile));
  }, [profile]);

  const handleCancel = () => {
    setInviteType(null);
    onCancel();
  };

  const onInvite = async values => {
    setSaving(true);
    const loginUrl = `${window.location.origin}${routes.user.log_in}`;

    try {
      const existingTeamMember = Object.keys(profile.team).find(id => Object.keys(profile.team[id]).find(key => profile.team[id][key] === values.email));

      if (existingTeamMember) {
        NotificationManager.success(`${values.first_name} is already signed up as a team member`);

        setSaving(false);
      } else {
        const result = await inviteUser({
          firstName: values.first_name,
          lastName: values.last_name,
          email: values.email,
          relationship: values.relationship,
          type: values.type,
          inviteMessage: values.message,
          permissionLevel: values.permissions,
          profileId: profile.id,
          loginUrl,
        });

        NotificationManager.success(`${values.first_name} has been invited!`);

        // Navigate to the onboarding
        if (onboard) {
          history.push(
            routes.generate(routes.profile.onboard.intro, {
              profileId: profile.id,
            })
          );
        } else if (result.data.existing) {
          setSaving(false);
          onInvited();
        } else {
          // Perform a refetch of profiles to include the new team member
          await profilesStore.fetchProfiles(authUser.uid);
          setSaving(false);
          onInvited();
        }
      }
    } catch (e) {
      console.warn('Error in cloud function', e);

      setSaving(false);
    }
  };

  return (
    <>
      {!inviteType && <Invite className="invite-card__wrap" heading={heading} onSelect={setInviteType} onCancel={handleCancel} />}
      {inviteType === 'family' && (
        <InviteFamilyForm className="invite-card__wrap" onSubmit={onInvite} onCancel={handleCancel} saving={saving} isOwner={isOwner} />
      )}
      {inviteType === 'professional' && (
        <InviteProfessionalForm className="invite-card__wrap" onSubmit={onInvite} onCancel={handleCancel} saving={saving} isOwner={isOwner} />
      )}
      {inviteType === 'friend' && (
        <InviteFriendForm className="invite-card__wrap" onSubmit={onInvite} onCancel={handleCancel} saving={saving} isOwner={isOwner} />
      )}
    </>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(inject('stores')(observer(CardInvite)));
