/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useImperativeHandle, useRef} from 'react';
import {useField} from 'formik';
import classnames from 'classnames';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import 'react-datepicker/dist/react-datepicker.css';
import {getClassNames} from '../common/field';

dayjs.extend(customParseFormat);

const TextField = React.forwardRef(({name, label, wrapperClassName, prefix = '', required = false, readOnly, printing, ...props}, ref) => {
  const [{value, onChange}, {touched, error}] = useField(name);
  const [focused, setFocus] = useState(false);
  const inputRef = useRef();

  const memoizedFocus = useCallback(() => setFocus(true), [setFocus]);
  const memoizedBlur = useCallback(() => setFocus(false), [setFocus]);

  const isError = touched && !!error;

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  const {labelClassName, invalidClassName, errorClassName, focusClassName} = getClassNames(prefix);

  return (
    <label
      htmlFor="text"
      className={classnames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: focused,
      })}>
      {!readOnly && required && <span className={`required ${labelClassName}_required`}> * </span>}
      {label}
      {isError && <small className={errorClassName}> {error}</small>}
      {!printing && (
        <input name={name} value={value} onChange={onChange} readOnly={readOnly} {...props} onFocus={memoizedFocus} onBlur={memoizedBlur} ref={inputRef} />
      )}
      {printing && <p>{value}</p>}
      {props.helpertext && <small>{props.helpertext}</small>}
    </label>
  );
});

export default TextField;
