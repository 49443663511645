/* eslint-disable react/prop-types */
import React, {useMemo} from 'react';
import {Form} from 'informed';
import Modal from '../utilities/modal';
import {FieldTextarea} from '../common';
import {validateRequired} from '../common/validate';

export function PostEditButton({className, onEditClick}) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div className={`item -edit ${className ?? ''}`} onClick={onEditClick}>
      <i className="icon-pencil" />
    </div>
  );
}

export function PostEditModal({postText, handleDismiss, onEditConfirm}) {
  const initialValues = useMemo(() => ({text: postText}), [postText]);

  return (
    <Modal handleDismiss={handleDismiss}>
      <div className="heading -secondary">Edit post</div>
      <div />
      <Form initialValues={initialValues} onSubmit={onEditConfirm}>
        <FieldTextarea
          field="text"
          label="Edit post here"
          prefix="goal-edit"
          wrapperClassName="goal-edit__describe"
          placeholder={postText}
          validate={validateRequired}
          validateOnChange
        />

        <div className="action">
          <button type="button" className="button -tertiary" onClick={handleDismiss}>
            Cancel
          </button>
          <button type="submit" className="button -primary">
            Update Post
          </button>
        </div>
      </Form>
    </Modal>
  );
}
