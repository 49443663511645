/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {Icon} from '../common';

import '../../assets/scss/components/post/post.scss';

// eslint-disable-next-line react/prop-types
function GoalSelection({goals, selectedSteps, selectedFiltered, handleClickStep}) {
  const [open, setOpen] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [goalsSorted, setGoalsSorted] = useState([]);
  const handleGoalToggle = () => setOpen(!open);

  const stepClasses = classNames({
    'goals-select-steps': true,
    '-open': open,
  });

  const getPlaceholder = arr => {
    if (!arr || arr.length === 0) {
      return (
        <div className="title">
          <span>Tag a goal</span>
        </div>
      );
    }
    const name = arr[0].step;
    return (
      <div className="title">
        <span>
          {name}
          {arr.length > 1 ? ` (+ ${arr.length - 1})` : ''}
        </span>
      </div>
    );
  };

  const placeholder = getPlaceholder(selectedFiltered);

  useEffect(() => {
    const newGoalsSorted = [];

    // eslint-disable-next-line fp/no-mutating-methods
    Object.entries(goals || {})
      .sort((a, b) => a[1].sort - b[1].sort)
      .forEach(([id, goal]) => {
        if (!!goal.complete === showCompleted) {
          // eslint-disable-next-line fp/no-mutating-methods
          const steps = Object.entries(goal.steps || {}).sort((a, b) => a[1].sort - b[1].sort);
          // eslint-disable-next-line fp/no-mutating-methods
          newGoalsSorted.push({goalId: id, goalName: goal.name, steps});
        }
      });

    setGoalsSorted(newGoalsSorted);
  }, [goals, showCompleted]);

  return (
    <div className="goals-select-goal">
      <div
        className={`goals-select-header ${open && '-open'}`}
        onClick={() => {
          handleGoalToggle();
        }}>
        {placeholder}
        <div className="action">
          <i className={!open ? 'icon-right-open open' : 'icon-down-open close'} />
        </div>
      </div>
      {open && (
        <div>
          <div className="post-goal-tag-container">
            <span className={showCompleted ? 'post-goal-tag-pressed' : 'post-goal-tag-unpressed'} onClick={() => setShowCompleted(true)}>
              Completed goals
            </span>
            <span className={!showCompleted ? 'post-goal-tag-pressed' : 'post-goal-tag-unpressed'} onClick={() => setShowCompleted(false)}>
              Open goals
            </span>
          </div>
        </div>
      )}
      {open && (
        <div className={stepClasses}>
          <div>
            {(!goalsSorted || goalsSorted.length === 0) && (
              <div className="title">
                <h4 style={{fontWeight: 'lighter', textAlign: 'center', marginTop: 18, marginBottom: 12}}>
                  No {showCompleted ? 'completed' : 'open'} goals found
                </h4>
              </div>
            )}
            {goalsSorted &&
              goalsSorted.map(goalObj => (
                <div key={goalObj.goalId} className="goal-post">
                  <h5 className="goal-post__heading">{goalObj.goalName}</h5>
                  {goalObj.steps.map(([stepId, step]) => (
                    <div
                      key={stepId}
                      className={`goal-post-step ${selectedSteps[stepId] && 'goal-post-step--selected'}`}
                      onClick={() => handleClickStep(stepId, step.text)}>
                      <div className="goal-post-step__icon">
                        <Icon type="star" />
                      </div>
                      <div className="goal-post-step__label">{step.text}</div>
                      <div className="goal-post-step__checkbox">
                        <span className={`button -checkbox ${selectedSteps[stepId] && '-complete'}`}>
                          {selectedSteps[stepId] ? <Icon type="check" /> : <>&nbsp;</>}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default GoalSelection;
