/* eslint-disable react/prop-types */
import React, {useCallback} from 'react';
import {Form} from 'informed';
import dayjs from 'dayjs';

import {DateField, TimeField, Button} from '../common';
import {validateRequired} from '../common/validate';
import Modal from '../utilities/modal';

import '../../assets/scss/components/profile/add-appointment.scss';

const validateFields = ({date, time}) => ({
  date: !dayjs(date).isValid() ? 'must be a valid date' : undefined,
  time: !dayjs(time).isValid() ? 'must be a valid time' : undefined,
});

function AppointmentDropdown({loading, onDismiss, onAddAppointment}) {
  const onSubmit = useCallback(
    values => {
      const date = dayjs(values.date).format('YYYY-MM-DD');
      const time = dayjs(values.time).format('HH:mm:ss');
      onAddAppointment(dayjs(`${date} ${time}`).toISOString());
    },
    [onAddAppointment]
  );

  return (
    <Modal type="mini" handleDismiss={onDismiss} showDismiss>
      <Form onSubmit={onSubmit} validateFields={validateFields} className="add-appointment">
        <h3 className="add-appointment__heading">Add Appointment</h3>
        <DateField
          field="date"
          prefix="add-appointment"
          label="Enter Date"
          iconLeft="calendar"
          validate={validateRequired}
          placeholder="DD / MM / YY"
          dateFormat="dd/MM/yyyy"
          wrapperClassName="add-appointment__date"
        />
        <TimeField field="time" prefix="add-appointment" label="Enter Time" iconLeft="clock" validate={validateRequired} placeholder="00:00 AM" />
        <Button submit right type="secondary-light" loading={loading}>
          Add
        </Button>
      </Form>
    </Modal>
  );
}

export default AppointmentDropdown;
