/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {db} from '../../firebase';
import withAuthorization from '../session/withAuthorization';
import {errorLogger} from '../../helpers/errorLogger';

function CommentCreate({postId, profileId, authUser}) {
  const {uid} = authUser;

  const [input, setInput] = useState('');
  const [error, setError] = useState(null);

  const onSubmit = event => {
    setError(null);
    event.preventDefault();
    const id = db.createUUID();

    // Create a new post
    db.createPostComment({
      id,
      profileId,
      postId,
      comment: input,
      authorId: uid,
    })
      .then(() => {
        setInput('');
        setError(null);
      })
      .catch(newError => {
        setError(newError);
        errorLogger(newError);
      });
  };

  return (
    <div className="comment-create">
      <form onSubmit={onSubmit}>
        {error && <div className="comment-error">Something went wrong posting this comment</div>}
        {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
        <input autoFocus value={input} name="comment" placeholder="Add a comment" autoComplete="off" onChange={e => setInput(e.target.value)} />
      </form>
    </div>
  );
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(CommentCreate);
