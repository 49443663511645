/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, {useState} from 'react';
import {observer, inject} from 'mobx-react';
import distanceInWords from 'date-fns/formatDistanceStrict';

import Observer from 'react-intersection-observer';
import {db} from '../../firebase';
import {Avatar} from '../common';
import {UserNameLink} from '../user/user.link';
import withAuthorization from '../session/withAuthorization';
import Modal from '../utilities/modal';
import {errorLogger} from '../../helpers/errorLogger';

function Comment(props) {
  const {
    postId,
    data,
    authUser,
    profile,
    stores: {usersStore, notificationsStore},
  } = props;
  const author = usersStore.getUserById(data.authorId);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState(null);
  const [timer, setTimer] = useState(null);

  const onDeleteClick = event => {
    setDeleteModalOpen(true);
  };

  const onDeleteConfirm = () => {
    db.deleteComment({
      postId,
      profileId: profile.id,
      commentId: data.id,
    })
      .then(res => setDeleteModalOpen(false))
      .catch(err => {
        errorLogger(err);
        setDeleteErrors(err.message);
      });
  };

  const onViewChange = (inView, commentId) => {
    const notification = notificationsStore.getNotificationByCommentId(commentId);

    if (!notification) return false;

    if (inView) {
      const newTimer = setTimeout(() => {
        db.setNotificationSeen(authUser.uid, notification.id, true);
      }, 3000);

      setTimer(newTimer);
    } else {
      clearTimeout(timer);
      setTimer(null);
    }

    return true;
  };

  const notification = notificationsStore.getNotificationByCommentId(data.id);
  const hasNotificationClass = notification && !notification.seen ? 'unread' : 'read';

  return (
    <Observer threshold={0.1} onChange={inView => onViewChange(inView, data.id)}>
      <div className="comment-single">
        <div className={`comment-header ${hasNotificationClass}`}>
          {data.authorId === authUser.uid && (
            <div className="item -delete" onClick={onDeleteClick}>
              <i className="icon-trash" />
            </div>
          )}
          <Avatar src={author.profileImagePublic} />
          <span className="author">
            <UserNameLink user={author} profileId={profile.id} />
          </span>
          <span className="time">{distanceInWords(new Date(), data.created_at)}</span>
        </div>
        <div className="comment">{data.comment}</div>
        {deleteModalOpen && (
          <Modal handleDismiss={() => setDeleteModalOpen(false)}>
            <div className="header">Are you sure you want to delete this comment?</div>
            {deleteErrors && <p className="errors">Something went wrong deleting this comment</p>}
            <div className="action">
              <button type="button" className="button -tertiary" onClick={() => setDeleteModalOpen(false)}>
                Cancel
              </button>
              <button type="button" className="button -primary" onClick={onDeleteConfirm}>
                Delete
              </button>
            </div>
          </Modal>
        )}
      </div>
    </Observer>
  );
}

const authCondition = authUser => !!authUser;

export default inject('stores')(withAuthorization(authCondition)(observer(Comment)));
