/* eslint-disable react/prop-types */
import React, {useState, useCallback} from 'react';
import {Form, TextArea} from 'informed';
import classnames from 'classnames';
import {NotificationManager} from 'react-notifications';
import {inviteClient} from '../../firebase/db';
import {Modal, Button, Field, Footer} from '../common';
import {validateEmail, validateRequired} from '../common/validate';

import '../../assets/scss/components/invite/invite-form.scss';

function ModalInvite({isOpen, onDismiss}) {
  const [saving, setSaving] = useState(false);

  const onSubmit = useCallback(
    async values => {
      setSaving(true);
      const result = await inviteClient({...values});
      NotificationManager.success((result && result.data && result.data.message) || `${values.client_first_name} was invited`);
      setSaving(false);
      onDismiss();
    },
    [setSaving, onDismiss]
  );

  const onCancel = useCallback(
    e => {
      e.preventDefault();
      setSaving(false);
      onDismiss();
    },
    [onDismiss]
  );

  return (
    <Modal type="flex" isOpen={isOpen} handleDismiss={onDismiss} showDismiss>
      <div className="">
        <h2>Invite New Client / Family</h2>
        <p>Invite a client / family that you serve to sameview to provide better coordinated care and support.</p>
        <Form initialValues={{permissions: 'owner'}} onSubmit={onSubmit}>
          <div className="invite-form">
            <h3 className="invite-form__section-heading">Parent / Primary Carer</h3>
            <Field
              prefix="invite-form"
              label="First Name"
              field="client_first_name"
              wrapperClassName="invite-form__name"
              validate={validateRequired}
              validateOnChange
            />
            <Field
              prefix="invite-form"
              label="Last Name"
              field="client_last_name"
              wrapperClassName="invite-form__name"
              validate={validateRequired}
              validateOnChange
            />
            <Field prefix="invite-form" label="Email" field="client_email" placeholder="Email address" validate={validateEmail} validateOnChange />
            <h3 className="invite-form__section-heading">Name of person they care for</h3>
            <Field
              prefix="invite-form"
              label="First Name"
              field="first_name"
              wrapperClassName="invite-form__name"
              validate={validateRequired}
              validateOnChange
            />
            <Field prefix="invite-form" label="Last Name" field="last_name" wrapperClassName="invite-form__name" validate={validateRequired} validateOnChange />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="message" className={classnames('invite-form__label')}>
              Message<span className="invite-form__optional">optional</span>
              <TextArea
                id="message"
                field="message"
                placeholder="An email will be sent from sameview with details about how they can start using sameview and see you in their team. You can add any additional messages you like here"
              />
            </label>
            <Footer>
              <Button type="tertiary" onClick={onCancel} left>
                Cancel
              </Button>
              <Button type="primary" submit right loading={saving}>
                Invite
              </Button>
            </Footer>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default ModalInvite;
