/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, {useState, useCallback, useMemo, useImperativeHandle, useRef} from 'react';
import {Input, useField, useFieldState} from 'informed';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import TextareaAutosize from 'react-autosize-textarea';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {useFallback} from '../../hooks';

import 'react-datepicker/dist/react-datepicker.css';

dayjs.extend(customParseFormat);

export const getClassNames = prefix => ({
  labelClassName: `${prefix}__label`,
  fieldClassName: `${prefix}__field`,
  invalidClassName: `${prefix}__invalid`,
  errorClassName: `${prefix}__error`,
  focusClassName: `${prefix}__focus`,
  toggleClassName: `${prefix}__toggle`,
  buttonClassName: `${prefix}__toggle-button`,
  selectedClassName: `${prefix}__toggle-selected`,
});

export const Field = React.forwardRef(({field, label, wrapperClassName, prefix = '', required = false, readOnly, printing, ...props}, ref) => {
  const {value, touched, error} = useFieldState(field) ?? {};
  const [focused, setFocus] = useState(false);
  const inputRef = useRef();

  const memoizedFocus = useCallback(() => setFocus(true), [setFocus]);
  const memoizedBlur = useCallback(() => setFocus(false), [setFocus]);

  const isError = touched && !!error;

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  const {labelClassName, invalidClassName, errorClassName, focusClassName} = getClassNames(prefix);

  return (
    <label
      htmlFor="text"
      className={classnames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: focused,
      })}>
      {!readOnly && required && <span className={`required ${labelClassName}_required`}> * </span>}
      {label}
      {isError && <small className={errorClassName}> {error}</small>}
      {!printing && <Input field={field} readOnly={readOnly} {...props} onFocus={memoizedFocus} onBlur={memoizedBlur} ref={inputRef} />}
      {printing && <p>{value}</p>}
      {props.helpertext && <small>{props.helpertext}</small>}
    </label>
  );
});

export const FieldToggle = props => {
  const {fieldState, fieldApi, render, userProps} = useField(props);

  const [focused, setFocus] = useState(false);

  const {value, touched, error} = fieldState;
  const {setValue, setTouched} = fieldApi;
  const {onChange, onBlur, onFocus, prefix, label, wrapperClassName, options, required, readOnly} = userProps;

  const memoizedFocus = useFallback(() => setFocus(true), onFocus, [setFocus]);
  const memoizedBlur = useFallback(
    () => {
      setTouched(true);
      setFocus(false);
    },
    onBlur,
    [setTouched, setFocus]
  );

  const isError = touched && !!error;

  const {labelClassName, invalidClassName, errorClassName, focusClassName, selectedClassName, buttonClassName, toggleClassName} = getClassNames(prefix);

  const optionElements = useMemo(
    () =>
      Object.entries(options).map(([key, optionLabel]) => (
        <a
          key={key}
          href={`#${key}`}
          onFocus={memoizedFocus}
          onBlur={memoizedBlur}
          onClick={e => {
            e.preventDefault();
            setValue(key);
            if (onChange) onChange(key);
          }}
          className={classnames(buttonClassName, {
            [selectedClassName]: value === key,
          })}>
          {optionLabel}
        </a>
      )),
    [options, setValue, value, onChange, selectedClassName, buttonClassName, memoizedBlur, memoizedFocus]
  );

  return render(
    <label
      htmlFor="toggle"
      className={classnames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: focused,
      })}>
      {!readOnly && required && <span className={`required ${labelClassName}_required`}> * </span>}
      {label}
      {isError && <small className={errorClassName}> {error}</small>}
      <div className={toggleClassName}>{optionElements}</div>
    </label>
  );
};

export const FieldTextarea = props => {
  const {fieldState, fieldApi, render, userProps} = useField(props);

  const [focused, setFocus] = useState(false);

  const {value, touched, error} = fieldState;
  const {setValue, setTouched} = fieldApi;
  const {onChange, onBlur, onFocus, prefix, label, wrapperClassName, required, readOnly, printing, ...rest} = userProps;

  const memoizedFocus = useFallback(() => setFocus(true), onFocus, [setFocus]);
  const memoizedBlur = useFallback(
    () => {
      setTouched(true);
      setFocus(false);
    },
    onBlur,
    [setTouched, setFocus]
  );
  const memoizedChange = useFallback(e => setValue(e.target.value), onChange, [setValue]);

  const isError = touched && !!error;

  const {labelClassName, invalidClassName, errorClassName, focusClassName} = getClassNames(prefix);

  return render(
    <label
      htmlFor="textarea"
      className={classnames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: focused,
      })}>
      {!readOnly && required && <span className={`required ${labelClassName}_required`}> * </span>}
      {label}
      {isError && <small className={errorClassName}> {error}</small>}
      {!printing && (
        <TextareaAutosize id="textarea" readOnly={readOnly} {...rest} onFocus={memoizedFocus} onBlur={memoizedBlur} onChange={memoizedChange} value={value} />
      )}
      {printing && <p>{value}</p>}
      {props.helpertext && <small>{props.helpertext}</small>}
    </label>
  );
};

export const DateField = props => {
  const {fieldState, fieldApi, render, userProps} = useField(props);
  const [focused, setFocus] = useState(false);

  const {value, touched, error} = fieldState;
  const {setValue, setTouched} = fieldApi;
  const {onChange, onBlur, onFocus, prefix, label, iconLeft, placeholder, wrapperClassName, required, ...rest} = userProps;

  const isError = touched && !!error;

  const memoizedFocus = useFallback(() => setFocus(true), onFocus, [setFocus]);
  const memoizedBlur = useFallback(
    () => {
      setTouched(true);
      setFocus(false);
    },
    onBlur,
    [setTouched, setFocus]
  );
  const memoizedChange = useFallback(date => setValue(date), onChange, [setValue]);

  const {labelClassName, invalidClassName, errorClassName, focusClassName, fieldClassName} = getClassNames(prefix);

  return render(
    <label
      htmlFor="date"
      className={classnames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: focused,
      })}>
      {!rest.readOnly && required && <span className={`required ${labelClassName}_required`}> * </span>}
      {label} {isError && <small className={errorClassName}>{error}</small>}
      <DatePicker
        id="date"
        shouldCloseOnSelect
        {...rest}
        className={fieldClassName}
        placeholderText={placeholder}
        selected={dayjs(value).unix() * 1000}
        onFocus={memoizedFocus}
        onBlur={memoizedBlur}
        onChange={memoizedChange}
      />
      {props.helpertext && <small>{props.helpertext}</small>}
    </label>
  );
};

export const TimeField = props => {
  const {fieldState, fieldApi, render, userProps} = useField(props);
  const [focused, setFocus] = useState(false);

  const {value, touched, error} = fieldState;
  const {setValue, setTouched} = fieldApi;
  const {onChange, onBlur, onFocus, prefix, label, iconLeft, placeholder, wrapperClassName, required, readOnly, ...rest} = userProps;

  const isError = touched && !!error;

  const memoizedFocus = useFallback(() => setFocus(true), onFocus, [setFocus]);
  const memoizedBlur = useFallback(
    () => {
      setTouched(true);
      setFocus(false);
    },
    onBlur,
    [setTouched, setFocus]
  );
  const memoizedChange = useFallback(date => setValue(date), onChange, [setValue]);

  const {labelClassName, invalidClassName, errorClassName, focusClassName, fieldClassName} = getClassNames(prefix);

  return render(
    <label
      htmlFor="time"
      className={classnames(labelClassName, wrapperClassName, {
        [invalidClassName]: isError,
        [focusClassName]: focused,
      })}>
      {!readOnly && required && <span className={`required ${labelClassName}_required`}> * </span>}
      {label}
      {isError && <small className={errorClassName}> {error}</small>}
      <DatePicker
        id="time"
        shouldCloseOnSelect
        dateFormat="h:mm aa"
        timeIntervals={15}
        timeCaption="Time"
        readOnly={readOnly}
        {...rest}
        className={fieldClassName}
        showTimeSelect
        showTimeSelectOnly
        placeholderText={placeholder}
        selected={value}
        onFocus={memoizedFocus}
        onBlur={memoizedBlur}
        onChange={memoizedChange}
      />
    </label>
  );
};

export function DateRangeField({field}) {
  const {value: timeframeBegin} = useFieldState(`${field}.begin`) ?? {};
  const {value: timeframeEnd} = useFieldState(`${field}.end`) ?? {};

  return (
    <>
      <DateField
        prefix="goal-edit"
        field={`${field}.begin`}
        label="Time Frame"
        placeholder="DD/MM/YY"
        dateFormat="dd/MM/yyyy"
        selectsStart
        startDate={timeframeBegin}
        endDate={timeframeEnd}
      />
      -
      <DateField
        prefix="goal-edit"
        field={`${field}.end`}
        placeholder="DD/MM/YY"
        dateFormat="dd/MM/yyyy"
        selectsEnd
        startDate={timeframeBegin}
        endDate={timeframeEnd}
        minDate={timeframeBegin}
        popperPlacement="bottom-end"
      />
    </>
  );
}

export default Field;
