/* eslint-disable react/prop-types */
import {Dialog} from '@material-ui/core';
import React from 'react';

import TeamInviteCard from './card.invite';

function ModalInvite({onDismiss, isOpen, profile}) {
  return (
    <Dialog scroll="body" fullWidth maxWidth="md" open={!!isOpen} onClose={onDismiss}>
      <TeamInviteCard profile={profile} onCancel={onDismiss} onInvited={onDismiss} />
    </Dialog>
  );
}

export default ModalInvite;
